<template>
<div>
  <div id="main" class="site-main">

  <div id="main-content" class="main-content">


  <div id="page-title" class="page-title-block page-title-style-1 " style=""><div class="container"><div class="page-title-title"><h1 style="">  Школа лекторов</h1></div></div></div>




  <div class="block-content">
  	<div class="container">
  		<div class="panel row">

  			<div class="panel-center col-xs-12">
  				<article id="post-12163" class="post-12163 page type-page status-publish hentry">


  					<div class="entry-content post-content">
  												<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="fullwidth-block clearfix styled-marker-top" style="background-color: #ffffff;background-image: url(/assets/backgrounds/lectors-school/1.2.jpg);background-repeat: no-repeat; background-size: cover;background-position: center top;"><div class="container"><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 100px;"></div><div class="centered-box"><div class="sc-icon sc-icon-size-big  sc-icon-shape-square sc-simple-icon" style=""><span class="sc-icon-half-1" style="color: #ffffff;"><span class="back-angle">&#xe811;</span></span><span class="sc-icon-half-2" style="color: #ffffff;"><span class="back-angle">&#xe811;</span></span></div></div>
  	<div class="wpb_text_column wpb_content_element " >
  		<div class="wpb_wrapper">
  			<p>В этом году в «Школе лекторов» прошли обучение более 180 человек, а в дистанционной форме получили знания более 300 человек по всей России. Совместно с экспертами Российского общества «Знание» апробирована информационно-аналитическая система оценки качества работы и рейтинга лекторов. 100 человек из 35 регионов страны прислали свои видео-лекции для участия в конкурсе «Лучший лектор страны».</p>

  		</div>
  	</div>
  <div class="centered-box"><router-link class="sc-button "  :to="{name : 'shkolalektorov_articles' }" target="_self" style="background-color: #b5cb5c;">Новости проекта</router-link></div><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 213px;"></div></div></div></div></div></div></div></p>
  					</div><!-- .entry-content -->







  				</article><!-- #post-## -->

  			</div>


  		</div>


  	</div>
  </div>



  </div><!-- #main-content -->
</div>
</div>
</template>

<script>
import {rollup} from "@/lib/utils.js"
export default {
name : 'Projects_lectors_school',

mounted() {

  rollup()
}


}


</script>
