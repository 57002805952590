<template>
  <div id="app">
      <div id="page" class="layout-fullwidth">
  <Header mobile="false" />
<div ref="kkr" :style="fixviewstyle">
   <router-view/>
</div>
  <Footer/>
<!--
    <div id="nav">
      <router-link to="/home">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>

    -->
  </div>
  </div>
</template>


<script>
import Header from  "./layouts/header.vue"
import Footer from "./layouts/footer.vue"
import mainslider from "@/components/mainslider.vue"



export default {
  name : 'App',
  title: 'Ассоциация по развитию социального туризма',
  components : {
       Header,
       Footer,
       mainslider

      },
  computed : {
fixviewstyle() {

if(!this.$store.state.titleview.bannermode)
  return "padding-top : 120px;"


}

  },
  methods : {
handleScroll(e)
{
//const x = Math.round(window.pageXOffset)
const y=  Math.round(window.pageYOffset)

this.$store.commit('addscroll',y)


}

  },
  mounted(){


    console.log("main app mounted")
    if(!this.$isMobile())
    {
       window.addEventListener('scroll', this.handleScroll)

    console.log("not mobile")


          }

  }


}

</script>
