<template>

    <div id="custom_recent_posts-2" class="widget inline-column col-md-4 col-sm-6 col-xs-12 count-3 Custom_Recent_Posts"><h3 class="widget-title">Новые записи</h3><ul class="posts blog styled">

      <li v-for="news in newsitems" class="clearfix sc-pp-posts"><div class="sc-pp-posts-image"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}"><img :src="news.imagesrc" :alt="news.title.rendered"></router-link></div>

      <div class="sc-pp-posts-text"> <div class="sc-pp-posts-item"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}">{{news.title.rendered}}</router-link>
    </div>

      <div class="sc-pp-posts-date">{{news.postdateformated}}</div></div></li>
    </ul></div>



</template>
<script>
import {getwpposts} from "@/lib/utils.js"

export default {
name : 'footernewsblock',
props : ['cat','count'],
data(){

return {
newsitems : []
         }
},
mounted(){
this.getposts()
},

methods : {
      async getposts() {
          this.newsitems = await getwpposts(this.cat,this.count)
        }

    }
}
</script>
