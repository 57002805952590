<template>
<div class="site-title">
<h1>
<router-link to="/" rel="home">
<span class="logo logo-1x">
  <img src="/assets/logo/230-89.png" class="default" alt="" :style="isvisible">
<!--  <img src="http://aorst.ru/wp-content/uploads/2015/11/232-100.png" class="small" alt="" style="display : none;"> -->
</span>
<!--
<span class="logo logo-2x">
  <img src="http://aorst.ru/wp-content/uploads/2015/11/348-150.png" class="default" alt="">
  <img src="http://aorst.ru/wp-content/uploads/2015/11/197-85.png" class="small" alt="">
</span>
<span class="logo logo-3x">
<img src="http://aorst.ru/wp-content/uploads/2015/11/348-1501.png" class="default" alt="">
<img src="http://aorst.ru/wp-content/uploads/2015/11/297-128.png" class="small" alt="">
</span>
-->
                          </router-link>
</h1>
</div>
</template>

<script>
export default {
name : 'siteTitle',
props : ['visible'],
computed : {


},

data() {

  return {
isvisible : 'opacity : 1.0;'
  }
},
watch : {

}



}
</script>
