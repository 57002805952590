<template>
  <div>
   <div class="block-slideshow">
<!--
     <div class="preloader slideshow-preloader">

    </div>
-->
    <div class="sc-slideshow"><div class="preloader"></div><div class="sc-nivoslider">			<a href="http://www.peremena.travel/" target="_self" class="sc-nivoslider-slide">
        <img width="1920" height="500" src="/assets/backgrounds/slider/peremena-3.jpg" class=" wp-post-image" alt="" srcset="/assets/backgrounds/slider/peremena-3.jpg 1920w, /assets/backgrounds/slider/peremena-3-300x78.jpg 300w,/assets/backgrounds/slider/peremena-3-768x200.jpg 768w, /assets/backgrounds/slider/peremena-3-1024x267.jpg 1024w" sizes="(max-width: 1920px) 100vw, 1920px" />			<div class="sc-nivoslider-caption" style="display: none;">
          <div class="caption-right">
            <div class="sc-nivoslider-title">ПЕРЕМЕНА</div>
            <div class="clearboth"></div>
            <div class="sc-nivoslider-description"><p>УРОКИ В ГОРОДАХ РОСCИИ</p>
    </div>
          </div>
        </div>
            </a>
            <a href="http://xn--80abelycrjchuom4b.xn--p1ai/" target="_self" class="sc-nivoslider-slide">
        <img width="1920" height="500" src="/assets/backgrounds/slider/bsp-1.jpg" class=" wp-post-image" alt="" srcset="/assets/backgrounds/slider/bsp-1.jpg 1920w, /assets/backgrounds/slider/bsp-1-300x78.jpg 300w, /assets/backgrounds/slider/bsp-1-768x200.jpg 768w, /assets/backgrounds/slider/bsp-1-1024x267.jpg 1024w" sizes="(max-width: 1920px) 100vw, 1920px" />			<div class="sc-nivoslider-caption" style="display: none;">
          <div class="caption-right">
            <div class="sc-nivoslider-title">БАНК СОЦПУТЕВОК</div>
            <div class="clearboth"></div>
            <div class="sc-nivoslider-description"><p>ТУРИЗМ В ИНТЕРЕСАХ СТАРШЕГО ПОКОЛЕНИЯ</p>
    </div>
          </div>
        </div>
            </a>
      </div></div>
     </div>

    </div>
  </template>

  <script>
export default {

name : 'mainslider',
 mounted() {
console.log('slider mounted')
//this.$loadScript('/assets/plugins/LayerSlider/static/layerslider/js/greensock.js?ver=1.19.0')

//this.$loadScript('/assets/themes/scalia/js/jquery.nivo.slider.pack.js?ver=4.9.15')
//this.$loadScript('/assets/includes/common/sl.js')

this.$store.commit('bannermode',{bannermode : true})


this.$loadScript('/assets/themes/scalia/js/nivoslider-init.js')

this.$unloadScript('/assets/themes/scalia/js/nivoslider-init.js').then(
  () => {
      // Script was unloaded successfully
      this.$loadScript('/assets/themes/scalia/js/nivoslider-init.js')
    }
)







},
unmounted(){
console.log("banner - unmounting")
this.$store.commit('bannermode',{bannermode : false})

},
beforeUpdate()
{
console.log("banner - beforeUpdate")

},
beforeDestroy() {
console.log("slider ready to destroy")

},
destroyed()
{
  console.log('slider destroyed (((')
  this.$store.commit('bannermode',{bannermode : false})
},
created()
{
this.$store.commit('bannermode',{bannermode : true})

}


}
  </script>
