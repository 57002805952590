<template>

  <nav id="primary-navigation" class="site-navigation primary-navigation" role="navigation">
<button class="menu-toggle dl-trigger">Основное меню</button>
<ul id="primary-menu" class="nav-menu dl-menu styled no-responsive"><li id="menu-item-11471" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-11471 megamenu-first-element"><router-link to="/news">Новости</router-link>
<ul class="sub-menu  dl-submenu styled">
<li id="menu-item-11422" class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-11422 megamenu-first-element"><router-link to="/news/anonce">Анонсы</router-link></li>
<li id="menu-item-11424" class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-11424 megamenu-first-element"><router-link to="/news/events">Наши мероприятия</router-link></li>
<li id="menu-item-11832" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-11832 megamenu-first-element"><router-link to="/news/mediaonas">Медиа о нас</router-link></li>
</ul>
</li>
<li id="menu-item-11334" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-parent menu-item-11334 megamenu-first-element"><router-link to="/projects">Реализуемые проекты</router-link>
<ul class="sub-menu  dl-submenu styled">
<li id="menu-item-12170" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-12170 megamenu-first-element"><router-link to="/projects/shkolalektorov">Школа лекторов</router-link></li>
<li id="menu-item-11548" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-11548 megamenu-first-element"><router-link to="/projects/urokivgorodah">Уроки в городах России</router-link></li>
<li id="menu-item-11559" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-11559 megamenu-first-element"><router-link to="/projects/turizm">Туризм в интересах старшего поколения</router-link></li>
</ul>
</li>
<li id="menu-item-11706" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-11706 megamenu-first-element"><router-link to="/about">О нас</router-link></li>
<li id="menu-item-11873" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-11873 megamenu-first-element"><router-link to="/parthners">Партнёрам</router-link></li>
<li id="menu-item-11512" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-11512 megamenu-first-element"><router-link to="/contacts">Контакты</router-link></li>
<li id="menu-item-11419" class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-has-children menu-item-parent menu-item-11419 megamenu-first-element"><router-link to="/media">Медиа</router-link>
<ul class="sub-menu  dl-submenu styled">
<li id="menu-item-11468" class="menu-item menu-item-type-taxonomy menu-item-object-category menu-item-11468 megamenu-first-element"><router-link to="/media/video">Видео</router-link></li>
<li id="menu-item-11304" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-11304 megamenu-first-element"><router-link to="/media/presentation">Презентация</router-link></li>
</ul>
</li>
</ul>
</nav>

</template>

<script>
export default {
name : "Navmenu"


}
</script>
