<template>
<span>



        <a href="#page" class="scroll-top-button"></a>

        <toparea :v-if="topareavisible"/>




<div id="site-header-wrapper" class="header-on-slideshow" style="height: 130px; position: absolute;">
  <header id="site-header" :class="`site-header animated-header ${bannerviewclasses}`" role="banner" ref="siteheader" :style="bannerviewmodestyle">

    <div class="container">
      <div class="header-main logo-position-left">
              <siteTitle />
                <Navmenu/>
                      </div>
    </div>
      </header><!-- #site-header -->
    </div>
</span>

</template>


<script>
import Navmenu from "@/components/header/navmenu.vue"

import siteTitle from "@/components/header/sitetitle.vue"
import toparea from "@/components/header/toparea.vue"

export default {
  name: 'Header',
  components : {
    Navmenu,

    siteTitle,
    toparea

  },
  computed : {




topareavisible(){

//console.log(this.$store.state.pagescroll)

if(!this.$store.state.titleview.bannermode)
  if(this.$store.state.pagescroll >= 53)
  {
     this.setscroll(true)
     return false
   }
    else {
        this.setscroll(false)
        return true
    }

    if(this.$store.state.titleview.bannermode)
      if(this.$store.state.pagescroll >= 53)
      {

       return false
        }

},


    bannerviewclasses() {

const mode = this.$store.state.titleview.bannermode
const scroll = this.$store.state.pagescroll


if(mode && (scroll >= 53) )
{

  //console.log("fixed mode")
return 'fixed shrink'

}

if(mode)
{
  return 'header-on-slideshow'
}



return 'fixed '


},


   bannerviewmodestyle(){


     const mode = this.$store.state.titleview.bannermode
     const scroll = this.$store.state.pagescroll
    //  console.log('change banner mode to mode = '+mode)
if(mode && (scroll >= 53) ){


        return 'top:0px;'
     }


if(mode)
{
return 'top:32px;'

}else {

if(!this.pageonscroll)
     return 'top:40px;'
}



   }


  },
  data() {

    return {
pageonscroll  : false,


    }

  },
  props : ['mobile',],
  mounted() {

console.log('bannermode')
console.log(this.$store.state.titleview.bannermode)

},
methods : {
 setscroll(val)
 {
  this.pageonscroll = val
},
setpicvisible(){

this.picvisible = true

}


},
watch : {


}


}
</script>
