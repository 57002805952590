<template>
  <div class="top-area-search">
    <form role="search" method="get" id="top-area-searchform" class="searchform" @submit.prevent="getsearch">
<div>
<input type="text" value="" name="s" id="top-area-s" ref="sinputsmall" />
<button type="submit" id="top-area-searchsubmit" value="Search"></button>
</div>
</form>
</div>
</template>

<script>
export default {
name : 'headerSearchbar',
methods : {

getsearch(e)
{
console.log(this.$refs.sinputsmall.value)

const search = this.$refs.sinputsmall.value
this.$refs.sinputsmall.value = ''

this.$router.push({name : 'rubricatorsearch', params : {search , type : 'search'}})

}


  }
}
</script>
