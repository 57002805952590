<template>
<div>
  <div class="top-area-socials">	<div class="socials">
<div v-if="twitter" class="socials-item twitter"><a href="https://twitter.com/arst_ru" target="_blank" title="twitter">twitter</a></div>
    <div  v-if="facebook" class="socials-item facebook"><a href="https://www.facebook.com/arst.ru" target="_blank" title="facebook">facebook</a></div>
                                  <div  v-if="rss" class="socials-item rss"><a href="#" target="_blank" title="rss">rss</a></div>
</div>
</div>
</div>
</template>

<script>
export default {
name : 'socials_head',
data(){
 return {
   facebook : false,
   twitter : false,
   rss : true

 }

}


}
</script>
<style>


</style>
