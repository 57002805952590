<template>
  <div id="top-area" class="top-area top-area-style-1">
  <div class="container">
    <div class="top-area-items clearfix">
                  <socials_head/>
                                          <headerSearchbar />
                                  <div class="top-area-contacts"><div class="sc-contacts"><div class="sc-contacts-item sc-contacts-address">Москва, 107140, ул. Верхняя Красносельская, д. 3, стр. 1, 6 этаж</div><div class="sc-contacts-item sc-contacts-phone"> 8 (495) 7- 801-802</div><div class="sc-contacts-item sc-contacts-email"> <a href="mailto:info@aorst.ru">info@aorst.ru</a></div></div></div>
                          </div>
  </div>
</div>
</template>

<script>
import headerSearchbar from "@/components/headersearchbar.vue"
import socials_head from "@/components/socials-head.vue"
export default {
name  : 'toparea',
components : {headerSearchbar,socials_head}

}
</script>
