import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      ispagescroll : false,
      pagescroll : 0,
      titleview : {
        topview : true,
        bannermode : false

      }


  },
  mutations: {
    hidetitle(state,payload)
    {

     state .titleview.topview = false
   },
    showtitle(state,payload)
    {
      state.titleview.topview = true
    },
    bannermode(state,payload)
    {
    //  console.log('in store : bannermode ')
    //  console.log(payload)

      state.titleview.bannermode = payload.bannermode

    },
    addscroll(state,payload)
    {
        

      state.pagescroll = payload

    }
  },
  actions: {



  },
  modules: {
  }
})
