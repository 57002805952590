<template>
  <form id="mc4wp-form-1" class="mc4wp-form mc4wp-form-17" method="post" data-id="17" data-name=""><div class="mc4wp-form-fields"><p>
	<label></label>
	<input type="email" name="EMAIL" placeholder="Ваш email" required="">
</p>
<p>
	<input type="submit" value="Подписаться на новости">
</p></div><label style="display: none !important;">Leave this field empty if you're human: <input type="text" name="_mc4wp_honeypot" value="" tabindex="-1" autocomplete="off"></label><input type="hidden" name="_mc4wp_timestamp" value="1670414142">
<input type="hidden" name="_mc4wp_form_id" value="17">
<input type="hidden" name="_mc4wp_form_element_id" value="mc4wp-form-1"><div class="mc4wp-response"></div>
</form>
</template>

<script>
export default {
name : 'footerrassilka'


}

</script>
