import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueNativeSock from 'vue-native-websocket'
import VueMobileDetection from "vue-mobile-detection";
/*
import '@/assets/plugins/LayerSlider/static/layerslider/css/layerslider.css?ver=6.7.1'
import '@/assets/fonts/ls-google-fonts-css.css'
import '@/assets/fonts/load-google-fonts-css.css'
import '@/assets//plugins/contact-form-7/includes/css/styles.css?ver=5.0.1'



import '@/assets/themes/scalia/css/icons.css?ver=4.9.15'
import '@/assets/themes/scalia/css/reset.css?ver=4.9.15'
import '@/assets/themes/scalia/css/grid.css?ver=4.9.15'
import '@/assets/themes/scalia/style.css?ver=4.9.15'
import '@/assets/themes/scalia-scrollex-child/style.css?ver=4.9.15'

import '@/assets/themes/scalia/css/header.css?ver=4.9.15'
import '@/assets/themes/scalia/css/widgets.css?ver=4.9.15'
import '@/assets/themes/scalia/css/portfolio.css?ver=4.9.15'
import '@/assets/themes/scalia-scrollex-child/css/custom.css?ver=4.9.15'
import '@/assets/plugins/js_composer/assets/css/js_composer.min.css?ver=5.4.7'
import '@/assets/themes/scalia/js/fancyBox/jquery.fancybox.css?ver=4.9.15'
import '@/assets/themes/scalia/css/vc_elements.css?ver=4.9.15'
*/

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);
Vue.use(VueMobileDetection);

Vue.config.productionTip = false

const wsspath = ``
Vue.use(VueNativeSock, wsspath, {
  reconnection: true, // (Boolean) whether to reconnect automatically (false)
  reconnectionAttempts: 5, // (Number) number of reconnection attempts before giving up (Infinity),
  reconnectionDelay: 2000, // (Number) how long to initially wait before attempting a new (1000)
  connectManually: true,
    })


const vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//const wsspath = `ws://${location.host}`




/*
const extscripts = [

'./assets/plugins/LayerSlider/static/layerslider/js/greensock.js?ver=1.19.0',
'./assets/includes/js/jquery/jquery.js?ver=1.12.4',
'./assets/includes/js/jquery/jquery-migrate.min.js?ver=1.4.1',
'./assets/plugins/LayerSlider/static/layerslider/js/layerslider.kreaturamedia.jquery.js?ver=6.7.1',
'./assets/plugins/LayerSlider/static/layerslider/js/layerslider.transitions.js?ver=6.7.1',
'./assets/includes/common/jwplayer.js?ver=4.9.15',
'./assets/themes/scalia-scrollex-child/js/jquery.simplr.smoothscroll.min.js?ver=4.9.15',

]

extscripts.forEach((item, i) => {

try {
Vue.loadScript(item)
} catch (e) {
  console.log(`script load error ${item}}`)
  console.log(e)
}


});
*/



//const vm = new Vue()

//vm.$connect()
//this.vvm=vm;
