import axios from "axios"
import "datejs"


async function getwppost(id)
{

const res = await axios.get(`/api/posts/${id}&_embed`)

if(res.data)
{
res.data.postdateformated = new Date(res.data.date).toString("dd.MM.yyyy")
res.data.posttimeformated = new Date(res.data.date).toString("HH:mm")
res.data.categoryname =  res.data._embedded["wp:term"][0][0].name
res.data.categoriesarray = []
res.data._embedded["wp:term"][0].forEach((dt, i2) => {

  res.data.categoriesarray.push({ id : dt.id, name : dt.name})

});


return res.data

 }

}


async function getwpposts(cat='any',count,page=1)
{

let url = `/api/posts&per_page=${count}&orderby=date&_embed&page=${page}`

if(cat != 'any')
  url = `${url}&categories=${cat}`

const response  = await axios.get(url)
if(response.data)
{

  response.data.forEach((item, i) => {


    console.log(item)

     response.data[i].totalpages = pagecount(response)

    //console.log(item['_embedded']['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url)
if(item['_embedded']['wp:featuredmedia'])
{
  if(item['_embedded']['wp:featuredmedia'][0])
   {
     response.data[i].imagesrc = item['_embedded']['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
   }
   else {

         response.data[i].imagesrc = ''
   }
}
 else {

    response.data[i].imagesrc = ''
 }


if(!item._embedded["wp:featuredmedia"])
 response.data[i].imagesrcmedium = ''

if(item._embedded["wp:featuredmedia"])
 if(!item._embedded["wp:featuredmedia"][0])
   response.data[i].imagesrcmedium = ''

response.data[i].imagesrcmedium =''

if(item._embedded["wp:featuredmedia"])
    if(item._embedded["wp:featuredmedia"][0])
      if(item._embedded["wp:featuredmedia"][0].media_details.sizes.medium.source_url)
        {
          response.data[i].imagesrcmedium = item._embedded["wp:featuredmedia"][0].media_details.sizes.medium.source_url
          }else {
            response.data[i].imagesrcmedium = response.data[i].imagesrc
          }



  response.data[i].postdateformated = new Date(item.date).toString("dd.MM.yyyy")
  response.data[i].posttimeformated = new Date(item.date).toString("HH:mm")


  response.data[i].categoryname =  item._embedded["wp:term"][0][0].name

   response.data[i].categoriesarray = []

    item._embedded["wp:term"][0].forEach((dt, i2) => {

      response.data[i].categoriesarray.push({ id : dt.id, name : dt.name})

    });



  });

  return response.data
  }

}

async function getwpprevpost(date,cat)
{

const res = await axios.get(`/api/posts&before=${date}&categories=${cat}&per_page=1&orderby=date`)

if(res.data)
{
//console.log(res.data)
if(res.data[0])
  return res.data[0].id

return 0
    }
return 0
}

async function getwpnextpost(date,cat)
{

const res = await axios.get(`/api/posts&after=${date}&categories=${cat}&per_page=1&orderby=date&order=asc`)

if(res.data)
{
//console.log(res.data[0].id)
if(res.data[0])
  return res.data[0].id

return 0
    }
return 0
}


async function gettagscloud()
{

const res = await axios.get('/api/tags&per_page=100&_embed')

if(res.data)
{

res.data.forEach((item, i) => {
res.data[i].url = `tags/${item.name}`

});


//console.log(res.data)

 return res.data
    }


}


async function getprevcalendar(y=0,m=0)
{
  let  curdatefirst = new Date().toString("yyyy-MM-01")

if( (y != 0) && (m != 0))
{
     let mm = parseInt(m)
       if(m < 10)
          mm = `0${mm}`

     curdatefirst = `${y}-${mm}-01`
}



const res = await axios.get(`/api/posts&per_page=1&orderby=date&_embed&page=1&before=${curdatefirst}T00:00:00`)

if(!res.data)
{
return {status : false}

}

if(!res.data[0])
return {status : false}

const date = res.data[0].date

const dt = new Date(date)

let month = dt.getMonth()+1
   if(month < 10)
     month = `0${month}`

const year = dt.getFullYear()

const from = `${year}-${month}-01`
const to  = new Date(year, dt.getMonth()+1, 0).toString("yyyy-MM-dd")
//console.log(`calendar : from ${from}`)
//console.log(`calendar : to ${to}`)

return {
        status : true,
        month : month,
        year :  year

        }

}


async function getnextcalendar(y=0,m=0)
{

  let  curdatefirst = new Date().toString("yyyy-MM-01")

  if( (y != 0) && (m != 0))
  {
     let mm = parseInt(m)
       if(m < 10)
          mm = `0${mm}`

       const dt = new Date(`${y}-${mm}-01`)
     curdatefirst = new Date(y, dt.getMonth()+1, 0).toString("yyyy-MM-dd")
     //console.log(`getnextcalendar`)
     //console.log(curdatefirst)
  }

//console.log(`getnextcalendar`)
 //console.log(`/api/posts&per_page=1&order=asc&orderby=date&_embed&page=1&after=${curdatefirst}T23:59:59`)

  const res = await axios.get(`/api/posts&per_page=1&order=asc&orderby=date&_embed&page=1&after=${curdatefirst}T23:59:59`)

  if(!res.data)
  {
  return {
    status : false
        }

  }

  if(!res.data[0])
  return  {
        status : false
          }

  const date = res.data[0].date

  const dt = new Date(date)

  let month = dt.getMonth()+1
   if(month < 10)
     month = `0${month}`

  const year = dt.getFullYear()

  const from = `${year}-${month}-01`
  const to  = new Date(year, dt.getMonth()+1, 0).toString("yyyy-MM-dd")
  //console.log(`calendar : from ${from}`)
  //console.log(`calendar : to ${to}`)

  return {
        status : true,
        month : month,
        year :  year

        }




}


async function getcurmonthposts(y=0,m=0)
{

  let  curdatelast = new Date().toString("yyyy-MM-01")



  if( (y !=0) && (m != 0))
  {
     let mm = parseInt(m)
       if(m < 10)
          mm = `0${mm}`

       const dt =new Date(`${y}-${mm}-01`)
     curdatelast = new Date(y, dt.getMonth()+1, 0).toString("yyyy-MM-dd")
  }


  let  curdatefirst = new Date().toString("yyyy-MM-01")

if( (y != 0) && (m != 0))
{
     let mm =parseInt(m)
            if(m < 10)
          mm = `0${mm}`

     curdatefirst = `${y}-${mm}-01`
     console.log(`curdatefirst = ${curdatefirst}`)
}
console.log(`/api/posts&per_page=100&orderby=date&_embed&page=1&before=${curdatelast}T23:59:59&after=${curdatefirst}T00:00:00`)
const res = await axios.get(`/api/posts&per_page=100&orderby=date&_embed&page=1&before=${curdatelast}T23:59:59&after=${curdatefirst}T00:00:00`)

if(!res.data)
 return {
   status : false
 }

if(!res.data[0])
  return {
   status : false
      }

let dates = []

res.data.forEach((item, i) => {

  const date = item.date
const day = new Date(date).toString("dd")
dates.push(day)

});

return {
status : true,
dates

}

}


async function getpostsbycalendar(year,month=0,day=0,count)
{
let m = parseInt(month)
if(m < 10)
 m = `0${m}`

let d = '01'

if(day != 0)
{
  d = parseInt(day)
  if(d < 10)
   d = `0${d}`
}



const curdatefirst = `${year}-${month}-${d}`

let  curdatelast = new Date().toString("yyyy-MM-01")
const dt =new Date(`${year}-${m}-01`)
curdatelast = new Date(year, dt.getMonth()+1, 0).toString("yyyy-MM-dd")

if(day !=0)
{
curdatelast = `${year}-${m}-${d}`

}

const url = `/api/posts&per_page=${count}&orderby=date&_embed&page=1&before=${curdatelast}T23:59:59&after=${curdatefirst}T00:00:00`

console.log(`getpostsbycalendar : ${url}`)

const res = await axios.get(url)

if(!res.data)
 return {
   status : false
 }

if(!res.data[0])
  return {
   status : false
      }


const data = modyfyresponsedata(res)


  return {
    status : true,
    data,
    pages : pagecount(res)
  }
}
async function getpostsbysearch(key,count)
{

  const url = `/api/posts&per_page=${count}&orderby=date&_embed&page=1&search=${key}`
  const res = await axios.get(url)

  if(!res.data)
   return {
     status : false
   }

  if(!res.data[0])
    return {
     status : false
        }

console.log("getpostsbysearch() ")
console.log(res)
  const data = modyfyresponsedata(res)


    return {
      status : true,
      data,
      pages : pagecount(res)
    }




}
async function getpostsbytags(tagid,count)
{

const url = `/api/posts&per_page=${count}&orderby=date&_embed&page=1&tags=${tagid}`
const res = await axios.get(url)

if(!res.data)
 return {
   status : false
 }

if(!res.data[0])
  return {
   status : false
      }


const data = modyfyresponsedata(res)


  return {
    status : true,
    data,
    pages : pagecount(res)
  }

}



function modyfyresponsedata(response)
{

  response.data.forEach((item, i) => {
    console.log(item)
    //console.log(item['_embedded']['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url)

if(response.data[i].imagesrc = item['_embedded']['wp:featuredmedia'])
  {
    response.data[i].imagesrc = item['_embedded']['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
  }
  else {
    response.data[i].imagesrc = "#"
  }

if( item._embedded["wp:featuredmedia"] )
{
  if(item._embedded["wp:featuredmedia"][0].media_details.sizes.medium.source_url)
  {
    response.data[i].imagesrcmedium = item._embedded["wp:featuredmedia"][0].media_details.sizes.medium.source_url
  }else {
  response.data[i].imagesrcmedium = response.data[i].imagesrc
  }

}
else {
   response.data[i].imagesrcmedium = "#"
}

  response.data[i].postdateformated = new Date(item.date).toString("dd.MM.yyyy")
  response.data[i].posttimeformated = new Date(item.date).toString("HH:mm")


  response.data[i].categoryname =  item._embedded["wp:term"][0][0].name

   response.data[i].categoriesarray = []

    item._embedded["wp:term"][0].forEach((dt, i2) => {

      response.data[i].categoriesarray.push({ id : dt.id, name : dt.name})

    });



  })

  return response.data
}

function pagecount(res)
{
let pages = 1
console.log('pagecount')
//console.log(res)

if(res.headers['x-wp-totalpages'])
{
  pages = parseInt(res.headers['x-wp-totalpages'])
  console.log("total pages "+pages)
  return pages
}
return pages
}

function postscount(res)
{

  let posts = 1
  console.log('pagecount')
  console.log(res)

  if(res.headers['x-wp-total'])
  {
    posts = parseInt(res.headers['x-wp-total'])
    console.log("total pages "+posts)
    return posts
  }
  return posts

}


function rollup(y=0)
{

window.scrollTo(0, y)
}

export {getwppost, getwpprevpost,getwpnextpost,  getwpposts, gettagscloud, getprevcalendar, getnextcalendar, getcurmonthposts, getpostsbytags, getpostsbycalendar,getpostsbysearch, rollup}
