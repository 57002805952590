<template>
  <div class="sc_client-carousel" data-ll-item-delay="0"> <div class=" sc_client_carousel-items" data-autoscroll='0'>

<carousel :perPage="4"  :navigationEnabled="true"  :paginationEnabled="false" :navigationClickTargetSize="10"

   >
<slide >

  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right">   <img width="360" height="125" src="/assets/parthners-corousel/Министерство-Культуры-Российской-Федерации-scalia-person.png" class=" wp-post-image" alt="Министерство Культуры Российской Федерации" /> </div>
</slide>

<slide>
  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right"> <img width="360" height="125" src="/assets/parthners-corousel/Логотип-РОЗ-scalia-person.png" class=" wp-post-image" alt="Логотип РОЗ" /> </div>
</slide>

  <slide>
  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right"> <img width="360" height="125" src="/assets/parthners-corousel/ТАРР-scalia-person.png" class=" wp-post-image" alt="ТАРР" /> </div>
</slide>

<slide>
  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right"> <img width="360" height="125" src="/assets/parthners-corousel/логотип-РФВ-scalia-person.png" class=" wp-post-image" alt="логотип РФВ" /> </div>
</slide>

<slide>
  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right"> <img width="360" height="125" src="/assets/parthners-corousel/МПГУ-лого-scalia-person.png" class=" wp-post-image" alt="МПГУ лого" /> </div>
</slide>

<slide>
  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right"> <img width="360" height="125" src="/assets/parthners-corousel/ass-nkhp-scalia-person.png" class=" wp-post-image" alt="ass-nkhp" /> </div>
</slide>

<slide>
  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right"> <img width="360" height="125" src="/assets/parthners-corousel/Министерство-культуры-и-московской-области-новое-scalia-person.png" class=" wp-post-image" alt="Министерство культуры и московской области новое" /> </div>
</slide>

<slide>
  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right"> <img width="360" height="125" src="/assets/parthners-corousel/министерство-культуры-архангельской-области-scalia-person.png" class=" wp-post-image" alt="министерство культуры архангельской области" /> </div>
</slide>

<slide>
  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right"> <img width="360" height="125" src="/assets/parthners-corousel/2-scalia-person.png" class=" wp-post-image" alt="2" /> </div>
</slide>

<slide>
  <div class="sc-client-item lazy-loading-item" data-ll-effect="drop-right"> <img width="360" height="125" src="/assets/parthners-corousel/министерство-развития-предпринимательства-торговли-и-внешних-связей-логотип-2-scalia-person.png" class=" wp-post-image" alt="министерство развития предпринимательства, торговли и внешних связей логотип 2" /> </div>
</slide>
</carousel>
  </div>

</div> </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
export default {
components : {Carousel, Slide},
name : 'parthnerscarousel',
mounted() {

  //this.$loadScript('/assets/themes/scalia/js/clients-grid-carousel.js')
  //this.$unloadScript('/assets/themes/scalia/js/clients-grid-carousel.js').then(
    //() => {
      //  // Script was unloaded successfully
      //  this.$loadScript('/assets/themes/scalia/js/clients-grid-carousel.js')
    //  }
  //)


},
beforeDestroy() {
console.log("slider ready to destroy")

},
destroyed()
{
  console.log('courusel destroyed (((')
this.$unloadScript('/assets/themes/scalia/js/clients-grid-carousel.js')
},
created()
{
console.log('courusel created')

}


}
</script>



<style>
.VueCarousel-navigation-button {

color : #418f9a !important;

}


</style>
