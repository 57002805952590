<template>
  <div>
    <div id="main" class="site-main">

        <div id="main-content" class="main-content">

        <component is="mainslider"/>

        <div id="page-title" class="page-title-block page-title-style-1 has-background-image" style="background-image: url(/assets/backgrounds/title/06.jpg);"><div class="container"><div class="page-title-title"><h1 style="">  Ассоциация развития социального туризма</h1></div></div></div>




        <div class="block-content">
        	<div class="container">
        		<div class="panel row">

        			<div class="panel-center col-xs-12">
        				<article id="post-5727" class="post-5727 page type-page status-publish hentry">


        					<div class="entry-content post-content">
        												<div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner vc_custom_1420800635046"><div class="wpb_wrapper">
        	<div class="wpb_text_column wpb_content_element " >
        		<div class="wpb_wrapper">
        			<h2 style="text-align: center;">Новости</h2>

        		</div>
        	</div>
        </div></div></div></div> <newsblock :category="newscategory" count="3" /> <div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="fullwidth-block clearfix" style="background-color: #f1f5f8;background-image: url(/assets/backgrounds/main/arst-index.jpg);background-position: center top;background-attachment: fixed;padding-top: 80px;padding-bottom: 45px;padding-left: 25px;padding-right: 25px;"><div class="container"><div class="vc_row wpb_row vc_inner vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-6"><div class="vc_column-inner "><div class="wpb_wrapper">
        	<div class="wpb_text_column wpb_content_element " >
        		<div class="wpb_wrapper">
        			<h3>ЦЕЛИ АРСТ</h3>

        		</div>
        	</div>
        <div class="sc-dropcap sc-dropcap-shape-circle sc-dropcap-style-medium"><span class="sc-dropcap-letter" style="color: #ffffff;background-color: #ff7070;">А</span></div>
        	<div class="wpb_text_column wpb_content_element " >
        		<div class="wpb_wrapper">
        			<p>Создание условий для развития туризма для социально не защищенных слоев населения. Объединение существующих и планируемых концепций развития социального туризма в регионах РФ и соответствующих ведомствах.</p>
        <p>Взаимодействие с профильными ведомствами в рамках рабочих групп для формирования специальных программ по социальному туризму для социально незащищенных групп населения по категориям: медицина, религия, природа, образование, межэтнический обмен, военно-патриотическая тематика и другим.</p>
        <p>При помощи «Социального туристического паспорта» создание в регионах зон, на базе которых будут реализованы маршруты в рамках специальных проектов.</p>

        		</div>
        	</div>
        </div></div></div><div class="wpb_column vc_column_container vc_col-sm-6"><div class="vc_column-inner "><div class="wpb_wrapper"><div class="sc-image sc-wrapbox sc-wrapbox-style-default rounded-corners sc-wrapbox-position-below" style=""><div class="sc-wrapbox-inner"><img class="sc-wrapbox-element img-responsive" src="/assets/logo/arst-500-600.png" alt=""/></div></div></div></div></div></div></div></div></div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner vc_custom_1420800665201"><div class="wpb_wrapper"><div class="fullwidth-block clearfix" style="background-color: #f1f5f8;background-image: url(/assets/backgrounds/main/arst-index-1.jpg);background-position: center top;background-attachment: fixed;padding-top: 20px;padding-bottom: 50px;padding-left: 25px;padding-right: 25px;"><div class="container">
        	<div class="wpb_text_column wpb_content_element " >
        		<div class="wpb_wrapper">
        			<h2 style="text-align: center;">НАШИ ПАРТНЕРЫ</h2>

        		</div>
        	</div>
        <div class="sc-client-set-title"> </div>

        <parthnerscarousel />

      </div></div></div></div></div></div><div class="vc_row wpb_row vc_row-fluid"><div class="wpb_column vc_column_container vc_col-sm-12"><div class="vc_column-inner "><div class="wpb_wrapper"><div  class="wpb_widgetised_column wpb_content_element">
        		<div class="wpb_wrapper">


        		</div>
        	</div>
        </div></div></div></div></p>
        					</div><!-- .entry-content -->







        				</article><!-- #post-## -->

        			</div>


        		</div>


        	</div>
        </div>



        </div><!-- #main-content -->

        <!-- Your Google Analytics Plugin is missing the tracking ID -->

        		</div><!-- #main -->


  </div>
</template>


<script>
import mainslider from "@/components/mainslider.vue"
import categories from "@/lib/categories.js"
import newsblock from "@/components/news/newsblock.vue"
import parthnerscarousel from "@/components/main/parthnerscarousel.vue"
export default {
name : "Main",
data(){
return {
newscategory : categories.events
}
},
components : {mainslider, newsblock,parthnerscarousel},
mounted(){

console.log("main.vue mounted")

},
unmounted(){

console.log('main.vue unmounted')

}

}
</script>
