const categories= {

4 : 'events',
8 : 'foto',
5 : 'anonce',
6 : 'media',
3 : 'video',
7 : 'shkolalektorov_articles',

anonce : 5,
events : 4,  // Наши мероприятия
shkolalektorov_articles : 7,
foto : 8,
video : 3,
media : 6


}


export default categories
