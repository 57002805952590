<template>
<div>
  <div id="footer-socials" class="socials">
                                  <div v-if="twitter" class="footer-socials-item twitter"><a href="https://twitter.com/arst_ru" target="_blank" title="twitter">twitter</a></div>
                                                    <div v-if="facebook" class="footer-socials-item facebook"><a href="https://www.facebook.com/arst.ru" target="_blank" title="facebook">facebook</a></div>
                                                  <div v-if="rss" class="footer-socials-item rss"><a href="#" target="_blank" title="rss">rss</a></div>
                          </div><!-- #footer-socials -->
</div>
</template>

<script>
export default {
name : 'socials',
data(){
 return {
   facebook : false,
   twitter : false,
   rss : true

 }

}


}
</script>
<style>


</style>
