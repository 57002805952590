<template>
<div>


  <div class="vc_row wpb_row vc_row-fluid">
    <div class="wpb_column vc_column_container vc_col-sm-12">
      <div class="vc_column-inner ">
        <div class="wpb_wrapper">
          <div class="blog blog-style-styled_list2 blog-style-timeline clearfix ">

            <article v-for="news in newsitems" :id="news.id" :class="`post-${news.id} post type-post status-publish format-standard has-post-thumbnail hentry category-events`">
                 <div class="item-post-container">
                    <div class="item-post clearfix">
                       <div class="post-image">
                          <router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}"><img width="256" height="256" :src="news.imagesrc" class=" wp-post-image" alt="" :srcset="news.imagesrc" sizes="(max-width: 256px) 100vw, 256px" /></router-link> <div class="post-date-wrapper"><div class="post-date"><span class="entry-date">{{news.postdateformated}}</span><span class="post-time">
                              <span>{{news.posttimeformated}}</span></span></div></div> </div> <div class="post-text"> <header class="entry-header"> <div class="entry-title"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}" rel="bookmark">{{news.title.rendered}} </router-link> </div> <div class="entry-meta"> <span class="tag-links">
                                       <router-link :to="`/rubricator/${news.categories[0]}`" :title="`Посмотреть все посты в ${news.categoryname}`">{{news.categoryname}}</router-link> </span> </div>
                              </header>  <div class="entry-content"> <div v-html="news.excerpt.rendered"></div> <div class="clearfix entry-info"> <div class="styled-blog-meta"> <span class="read-more-link"><router-link :to="{ name: 'newscontent', params: {  contentdata: news, id : news.id }}"> Далее</router-link></span>
                          </div> </div> </div> </div> </div></div>
            </article>


  </div><div class="clearboth"></div><div class="sc-divider" style="margin-bottom: 10px;"></div><div class="centered-box"><router-link class="sc-button "  to="/news/all" target="_self" style="">Все новости АРСТ</router-link></div></div></div></div></div>




</div>
</template>

<script>
import axios from "axios"
import "datejs"
export default {
name : "newsblock",
props : [
'category','count'
    ],

data() {

return {
newsitems : []
 }

},
mounted() {

this.getnews()

},
methods : {

async getnews()  {

//console.log("Getting news")
const url = `/api/posts&per_page=${this.count}&orderby=date&categories=${this.category}&_embed`
console.log('newsblock')
console.log(url)
const response  = await axios.get(url)

console.log(response.data)

response.data.forEach((item, i) => {
  //console.log(item['_embedded']['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url)
if(item['_embedded']['wp:featuredmedia'])
{
  response.data[i].imagesrc = item['_embedded']['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url
}
else {
response.data[i].imagesrc = "#"

}

response.data[i].categoryname = item._embedded["wp:term"][0][0].name
response.data[i].postdateformated = new Date(item.date).toString("dd.MM.yyyy")
response.data[i].posttimeformated = new Date(item.date).toString("HH:mm")

response.data[i].categoriesarray = []

 item._embedded["wp:term"][0].forEach((dt, i2) => {
   response.data[i].categoriesarray.push({ id : dt.id, name : dt.name})
        });

});



this.newsitems = response.data


    }




}

}

</script>
