import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main/main.vue'
import Projects_lectors_school from '../views/projects/lectors_school/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about/index.vue')
  },
  {
    path : '/contacts',
    name : 'contacts',
    component : () => import( '../views/contacts/index.vue')
  },
  {
    path : '/media',
    name : 'media',
    component : () => import('../views/media/index.vue'),
    children : [



    ]
  },
  {
   path : '/media/video',
   name : 'video',
   component : ()=> import ('../views/media/video/index.vue')
 },
 {
  path : '/media/video/:page',
  name : 'videopages',
  component : ()=> import ('../views/media/video/index.vue')
},

 {
  path : '/media/foto',
  name : 'foto',
  component : ()=> import ('../views/media/foto/index.vue')
},
{
 path : '/media/foto/:page',
 name : 'fotopages',
 component : ()=> import ('../views/media/foto/index.vue')
},


 {
   path : '/media/presentation',
   name : 'presentation',
   component : () => import ('../views/media/presentation/index.vue')
 },

  {
    path : '/parthners',
    name : 'parthners',
    component : () => import('../views/parthners/index.vue')
  },
  {
    path : '/projects',
    name : 'projects',
    component : () => import('../views/projects/index.vue'),
      },
      {
        path : '/projects/urokivgorodah',
        name : 'urokivgorodah',
        component : () => import ('../views/projects/uroki_v_gorodah/index.vue')
      },
     {
      path : '/projects/shkolalektorov',
      name : 'shkolalektorov',
      component : Projects_lectors_school
    },
    {
    path : '/projects/shkolalektorov/articles',
    name : 'shkolalektorov_articles',
    component : () => import ('../views/projects/lectors_school/articles/index.vue')
    },
    {
      path : '/projects/shkolalektorov/articles/:page',
      name : 'shkolalektorov_articles',
      component : () => import ('../views/projects/lectors_school/articles/index.vue')

    },

  {
    path : '/projects/turizm',
    name : 'turizm',
    component : () => import('../views/projects/turizm/index.vue')

  },



  {
   path : "/news",
   name : "news",
   component : () => import ("../views/news/index.vue"),
    children : [
        ]

  },
  {
  path : '/news/mediaonas',
  name : 'mediaonas',
  component : () => import ("../views/news/mediaonas/index.vue")
  },
  {
   path : '/news/anonce',
   name : 'anonce',
   component : () => import('../views/news/anonce/index.vue')

 },
 {
  path : '/news/events',
  name : 'events',
  component : () => import ('../views/news/events/index.vue')
 },
 {
   path : '/news/events/:page',
   name : 'eventspages',
   component : () => import ('../views/news/events/index.vue')
    },
 {
   path : '/news/all',
   name : 'allnews',
   component : () => import ('../views/news/all/index.vue')

 },
 {
   path : '/news/all/:page',
   name : 'allnewspages',
   component : () => import ('../views/news/all/index.vue')

 },

 {
   path : '/news/:id',
   name : 'newscontent',
   component : () => import ('../views/news/shownews.vue')

 },
            {
              path : '/rubricator/tags/:tagname',
              params : {type1 : "tags"},
              name : 'rubricatortags',
              component : () => import ('../views/rubricator/index.vue')

            },

              {
                path : '/rubricator/search/:search',
                name : 'rubricatorsearch',
                component : () => import ('../views/rubricator/index.vue')
              },

              {
                path : '/rubricator/calendar/:year/:month',
                name : 'rubricatorcalendar1',
                component : () => import ('../views/rubricator/index.vue')
              },
              {
                path : '/rubricator/calendar/:year/:month/:day',
                name : 'rubricatorcalendar2',
                component : () => import ('../views/rubricator/index.vue')
              },


 {
  path : '/rubricator/:category',
  name : 'rubricatorcategory',
  component : () => import ('../views/rubricator/index.vue')

},
{
 path : '/rubricator/:category/:page',
 name : 'rubricatorcategorypage',
 component : () => import ('../views/rubricator/index.vue')

}

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
