<template>
  <div>
    <footer id="colophon" class="site-footer" role="contentinfo">

			<div class="container"><div class="row">

<div class="row inline-row footer-widget-area" role="complementary">
	<div id="contacts-2" class="widget inline-column col-md-4 col-sm-6 col-xs-12 count-3 widget_contacts"><h3 class="widget-title">Контакты</h3><div class="sc-contacts"><div class="sc-contacts-item sc-contacts-address">Москва, 107140, ул. Верхняя Красносельская, д. 3, стр. 1, 6 этаж</div><div class="sc-contacts-item sc-contacts-phone">Телефон: 8 (495) 7- 801-802</div><div class="sc-contacts-item sc-contacts-email">Email: <a href="mailto:info@aorst.ru">info@aorst.ru</a></div></div></div>

  <footernewsblock cat=31 count=3 />



    <div id="mc4wp_form_widget-2" class="widget inline-column col-md-4 col-sm-6 col-xs-12 count-3 widget_mc4wp_form_widget"><h3 class="widget-title">Рассылка</h3>

<footerrassilka />
   <!-- / MailChimp for WordPress Plugin --></div></div><!-- .footer-widget-area -->
				<div class="row"><div class="col-md-4 col-xs-12"><div class="footer-site-info">2015 - {{new Date().getFullYear()}} © АРСТ</div></div></div>

				<div class="centered-box">
											<socials> </socials>
									</div>
			</div></div>

		</footer>


  </div>
</template>

<script>
import socials from "@/components/socials.vue"
import footernewsblock from "@/components/news/footernewsblock.vue"
import footerrassilka from "@/components/footer/footerrassilka.vue"
export default{
name : "Footer",
components : {socials,footernewsblock,footerrassilka},
mounted() {
console.log("footer mounted")

}

}
</script>
